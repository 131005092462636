import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { loggedIn } = storeToRefs(authStore);
  if (!loggedIn.value) {
    return navigateTo({
      path: `/login`,
      query: {
        next: to.path,
      },
    });
  }
});
